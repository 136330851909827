<template>
  <div>
    <dialog-form
      :dialog="dialog"
      :form-title="dnsFormTitle"
      :form-scheme="dnsFormScheme"
      :save-loading="dnsSaveLoading"
      :edited-item="dnsEditedItem"
      @changeEditedItem="dnsChangeEditedItem"
      @save="dnsSave"
      @close="closeDnsDialog"
    />
    <dialog-confirm
      :dialog="dnsDialogDelete"
      :title="$t('domains.providers.dns.dialog.removeConfirmTitle')"
      :confirm-loading="dnsRemoveLoading"
      @close="closeDialogDeleteClose"
      @confirm="dnsRemoveConfirm"
    />
  </div>
</template>

<script>
export default {
  name: 'DomainsDns',
  props: {
    dialog: Boolean,
    action: {
      type: String,
      default: 'create'
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    dnsLoading: false,
    dnsDialog: false,
    dnsFormTitle: '',
    dnsFormScheme: {},
    dnsSaveLoading: false,
    dnsEditedItem: {},
    dnsDialogDelete: false,
    dnsRemoveLoading: false
  }),
  watch: {
    dialog() {
      this.dnsEditedItem = Object.assign({}, {
        id: this.editedItem.id,
        domain: this.editedItem.domain,
        subdomain: this.editedItem.subdomain,
        content: this.editedItem.content,
        type: this.editedItem.type
      })
    }
  },
  async mounted () {
    this.dnsFormTitle = this.action === 'update'
      ? this.$t('domains.providers.dns.dialog.titleEdit')
      : this.$t('domains.providers.dns.dialog.titleAdd')
    this.dnsFormScheme = {
      type: {
        type: 'select',
        label: this.$t('domains.providers.dns.dialog.type'),
        items: ['A', 'CNAME', 'TXT'],
        createRules: 'req',
        md: 4,
        disabled: this.action === 'update'
      },
      subdomain: {
        type: 'text',
        label: this.$t('domains.providers.dns.dialog.name'),
        createRules: 'req',
        updateRules: [],
        md: 4,
        disabled: this.action === 'update'
      },
      content: {
        type: 'text',
        label: this.$t('domains.providers.dns.dialog.content'),
        createRules: 'req',
        updateRules: [],
        md: 4
      }
    }
  },
  created () {
    this.$parent.$on('dnsRemove', this.dnsRemove)
  },
  methods: {
    dnsChangeEditedItem (event) {
      this.dnsEditedItem = Object.assign({}, this.dnsEditedItem, event)
    },
    async dnsSave (e) {
      this.dnsSaveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          const response = await this.$store.dispatch('domainsProviders/dnsSet', this.dnsEditedItem)
          if (response.success === true) {
            await this.$toast.success(this.$t('domains.providers.dns.dialog.success'))
            this.closeDnsDialog()
          } else {
            await this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.dnsSaveLoading = false
      }
    },
    dnsRemove (item) {
      this.dnsDialogDelete = true
      this.dnsEditedItem = Object.assign({}, {
        id: item.id,
        domain: item.domain,
        subdomain: item.name,
        type: item.type,
        content: item.content
      })
    },
    async dnsRemoveConfirm () {
      this.dnsRemoveLoading = true
      try {
        const response = await this.$store.dispatch('domainsProviders/dnsRemove', this.dnsEditedItem)
        if (response.success === true) {
          await this.$toast.success(this.$t('domains.providers.dns.dialog.removeSuccess'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.dnsRemoveLoading = false
        this.closeDialogDeleteClose()
      }
    },
    closeDnsDialog() {
      this.$emit('close', this)
    },
    closeDialogDeleteClose() {
      this.dnsDialogDelete = false
      this.dnsEditedItem = Object.assign({}, {
        id: '',
        domain: '',
        subdomain: '',
        type: '',
        content: ''
      })
    }
  }
}
</script>
