<template>
  <dialog-form
    :dialog="dialog"
    :form-title="nssFormTitle"
    :form-scheme="nssFormScheme"
    :save-loading="nssSaveLoading"
    :edited-item="nssEditedItem"
    @changeEditedItem="nssChangeEditedItem"
    @save="nssSave"
    @close="nssDialogClose"
  />
</template>

<script>
export default {
  name: 'Nameservers',
  props: {
    dialog: Boolean,
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    nssFormTitle: '',
    nssFormScheme: {},
    nssSaveLoading: false,
    nssEditedItem: {}
  }),
  watch: {
    dialog() {
      this.nssEditedItem = Object.assign({}, this.editedItem)
    }
  },
  mounted () {
    this.nssFormTitle = this.$t('domains.providers.nss.dialog.title')
    this.nssFormScheme = {
      ns_records: {
        type: 'combobox',
        ref: 'nss',
        smallChips: true,
        chips: true,
        multiple: true,
        items: this.editedItem.ns_records,
        label: this.$t('domains.tableForm.nss'),
        hint: this.$t('domains.providers.nss.dialog.hint'),
        appendIcon: 'dns',
        appendTooltip: this.$t('domains.providers.nss.dialog.defaultNss'),
        appendCopyBtn: true,
        appendClickFn: () => this.setDefaultNss(),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: []
      }
    }
  },
  methods: {
    nssChangeEditedItem(changes) {
      this.nssEditedItem = Object.assign({}, this.editedItem, changes)
    },
    async nssSave(e) {
      this.nssSaveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          const response = await this.$store.dispatch('domainsProviders/nssSet', {
            id: this.nssEditedItem.id,
            domain: this.nssEditedItem.domain,
            nss: this.nssEditedItem.ns_records
          })
          if (response.success === true) {
            await this.$toast.success(this.$t('domains.providers.nss.dialog.success'))
            this.nssDialogClose()
          } else {
            await this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.nssSaveLoading = false
      }
    },
    nssDialogClose() {
      this.$emit('close', this)
    },
    async setDefaultNss() {
      const defaultNss = await this.$store.dispatch('domainsProviders/getDefaultNss', { id: this.nssEditedItem.id })
      this.nssEditedItem.ns_records = defaultNss
    }
  }
}
</script>

<style scoped>

</style>
