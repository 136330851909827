<template>
  <div>
    <dialog-form
      :dialog="dialog"
      :form-title="zoneFormTitle"
      :form-scheme="zoneFormScheme"
      :save-loading="zoneSaveLoading"
      :edited-item="zoneEditedItem"
      @changeEditedItem="zoneChangeEditedItem"
      @save="zoneSave"
      @close="closeZoneDialog"
    />
    <dialog-confirm
      :dialog="zoneDialogDelete"
      :title="$t('domains.cloudflare.zones.dialog.removeConfirmTitle')"
      :confirm-loading="zoneRemoveLoading"
      @close="zoneDialogDeleteClose"
      @confirm="zoneRemoveConfirm"
    />
  </div>
</template>

<script>
export default {
  name: 'CloudflareZone',
  props: {
    dialog: Boolean,
    action: {
      type: String,
      default: 'create'
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    zoneFormTitle: '',
    zoneFormScheme: {},
    zoneSaveLoading: false,
    zoneDialogDelete: false,
    zoneRemoveLoading: false,
    zoneEditedItem: {}
  }),
  watch: {
    dialog() {
      this.zoneEditedItem = Object.assign({}, {
        id: this.editedItem.id,
        zoneId: this.editedItem.zoneId,
        name: this.editedItem.name,
        ip: this.editedItem.ip,
        ssl: this.editedItem.ssl,
        autoHttpsRewrite: this.editedItem.autoHttpsRewrite,
        alwUseHttps: this.editedItem.alwUseHttps,
        devMode: this.editedItem.devMode
      })
    }
  },
  mounted () {
    this.zoneFormTitle = this.action === 'update'
      ? this.$t('domains.cloudflare.zones.dialog.titleEdit')
      : this.$t('domains.cloudflare.zones.dialog.titleAdd')
    this.zoneFormScheme = {
      name: {
        type: 'text',
        label: this.$t('domains.cloudflare.zones.dialog.zone'),
        createRules: 'req',
        updateRules: [],
        md: 4,
        disabled: this.action === 'update'
      },
      ip: {
        type: 'text',
        label: this.$t('domains.cloudflare.zones.dialog.ip'),
        createRules: 'req',
        md: 4
      },
      ssl: {
        type: 'select',
        label: this.$t('domains.cloudflare.zones.dialog.ssl'),
        items: ['full', 'flexible', 'strict', 'off'],
        createRules: 'req',
        md: 4
      },
      alwUseHttps: {
        type: 'switch',
        label: this.$t('domains.cloudflare.zones.dialog.alwUseHttps'),
        createRules: 'req',
        updateRules: [],
        md: 4,
        class: 'mt-3'
      },
      autoHttpsRewrite: {
        type: 'switch',
        label: this.$t('domains.cloudflare.zones.dialog.autoHttpsRewrite'),
        createRules: 'req',
        updateRules: [],
        md: 4,
        class: 'mt-3'
      },
      devMode: {
        type: 'switch',
        label: this.$t('domains.cloudflare.zones.dialog.devMode'),
        createRules: 'req',
        updateRules: [],
        md: 4,
        class: 'mt-3'
      }
    }
  },
  created () {
    this.$parent.$on('zoneRemove', this.zoneRemove)
  },
  methods: {
    zoneChangeEditedItem (event) {
      this.zoneEditedItem = event
    },
    async zoneSave (e) {
      this.zoneSaveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          const response = await this.$store.dispatch('cloudflare/zoneSave', this.zoneEditedItem)
          if (response.success === true) {
            await this.$toast.success(this.$t('domains.cloudflare.zones.dialog.success'))
            this.closeZoneDialog()
          } else {
            await this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.zoneSaveLoading = false
      }
    },
    closeZoneDialog () {
      this.$emit('close', this)
    },
    zoneRemove (item) {
      this.zoneDialogDelete = true
      this.zoneEditedItem = Object.assign({}, item)
    },
    async zoneRemoveConfirm () {
      this.zoneRemoveLoading = true
      try {
        const response = await this.$store.dispatch('cloudflare/zoneRemove', this.zoneEditedItem)
        if (response.success === true) {
          await this.$toast.success(this.$t('domains.cloudflare.remove.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.zoneRemoveLoading = false
        this.zoneDialogDeleteClose()
      }
    },
    zoneDialogDeleteClose () {
      this.zoneDialogDelete = false
      this.zoneEditedItem = {}
    }
  }
}
</script>

<style scoped>

</style>
