<template>
  <dialog-form
    :dialog="dialog"
    :form-title="formTitle"
    :form-scheme="formScheme"
    :save-loading="saveLoading"
    :edited-item="domainOwnerEditedItem"
    @changeEditedItem="domainOwnerChangeEditedItem"
    @save="changeDomainOwner"
    @close="changeDomainOwnerDialogClose"
  />
</template>

<script>
export default {
  name: 'ChangeDomainOwner',
  props: {
    dialog: Boolean,
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    formTitle: '',
    formScheme: {},
    saveLoading: false,
    domainOwnerEditedItem: {},
    newOwnerFieldRules: [],
    usersList: []
  }),
  watch: {
    async dialog(val) {
      if (val !== true) {
        return false
      }
      this.domainOwnerEditedItem = Object.assign({}, this.domainOwnerEditedItem, this.editedItem)
      this.usersList = await this.$store.dispatch('hostingServers/getHostingServerUsers', { id: this.editedItem.id })
      this.usersList = this.usersList.length > 0 ? this.usersList.map(u => {
        return {
          text: u.toUpperCase(),
          value: u,
          disabled: this.editedItem.currentOwner === u
        }
      }) : []
      this.formScheme.currentOwner.items = this.usersList
      this.formScheme.newOwner.items = this.usersList
      this.formScheme.newOwner.createRules = [
        v => !!v || 'Field is required',
        v =>
          (v => {
            let valid = false
            this.usersList.map(r => {
              if (r.value === v) {
                valid = true
              }
            })
            return valid
          })(v) || 'Invalid selected value'
      ]
    }
  },
  async mounted () {
    this.formTitle = this.$t('hosting.servers.domains.changeOwnerDialog.formTitle')
    this.domainOwnerEditedItem = Object.assign({}, this.editedItem)
    this.formScheme = {
      currentOwner: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.changeOwnerDialog.currentOwner'),
        items: this.usersList,
        createRules: [],
        updateRules: [],
        md: 6,
        disabled: true
      },
      newOwner: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.changeOwnerDialog.newOwner'),
        items: this.usersList,
        createRules: [],
        updateRules: [],
        md: 6
      }
    }
  },
  methods: {
    domainOwnerChangeEditedItem(changes) {
      this.domainOwnerEditedItem = Object.assign({}, this.editedItem, changes)
    },
    async changeDomainOwner(e) {
      this.saveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          const response = await this.$store.dispatch('hostingServers/changeDomainOwner', this.domainOwnerEditedItem)
          if (response.success === true) {
            await this.$toast.success(this.$t('The process of transferring data to the new owner of the site is started'))
            this.changeDomainOwnerDialogClose()
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.saveLoading = false
      }
    },
    changeDomainOwnerDialogClose() {
      this.$emit('close', this)
    }
  }
}
</script>
