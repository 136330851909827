<template>
  <dialog-form
    :dialog="dialog"
    :form-title="formTitle"
    :form-scheme="formScheme"
    :save-loading="saveLoading"
    :edited-item="currentItem"
    @changeEditedItem="currentItemChanges"
    @save="save"
    @close="close"
  />
</template>

<script>
export default {
  name: 'DomainTransfer',
  props: {
    dialog: Boolean,
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    formTitle: '',
    formScheme: {},
    saveLoading: false,
    currentItem: {}
  }),
  watch: {
    async currentItem(val) {
      if (val.futureServerId) {
        const futureServerUsers = await this.$store.dispatch('hostingServers/getHostingServerUsers', { id: val.futureServerId })
        if (futureServerUsers.length > 0) {
          this.formScheme.futureServerUser.disabled = false
          this.formScheme.futureServerUser.items = futureServerUsers
        }
        const futureServerIPs = await this.$store.dispatch('hostingServers/getHostingServerIp', { id: val.futureServerId })
        if (futureServerIPs.length > 0) {
          this.formScheme.futureServerIP.disabled = false
          this.formScheme.futureServerIP.items = futureServerIPs
        }
        // this.currentItem.withContent = true
        this.formScheme.withContent.disabled = false
      } else {
        this.formScheme.futureServerUser.disabled = true
        this.formScheme.futureServerUser.items = []

        this.formScheme.futureServerIP.disabled = true
        this.formScheme.futureServerIP.items = []

        this.currentItem.withContent = false
        this.formScheme.withContent.disabled = true
      }
    }
  },
  async mounted () {
    this.formTitle = this.$t('hosting.servers.domains.domainTransferDialog.formTitle')
    this.formScheme = {
      futureServerId: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.domainTransferDialog.serversList'),
        items: [],
        createRules: 'req',
        updateRules: [],
        md: 12,
        fn: async (i) => {
          const response = await this.$store.dispatch('hostingServers/getVestaHostingServersList')
          const currentDomainServer = response.find(s => s.value === this.editedItem.id)
          if (currentDomainServer) {
            currentDomainServer.disabled = true
          }
          this.formScheme.futureServerId.items = response
        }
      },
      futureServerUser: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.domainTransferDialog.serverUsersList'),
        items: [],
        createRules: 'req',
        updateRules: [],
        md: 6,
        disabled: true
      },
      futureServerIP: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.domainTransferDialog.serverIPsList'),
        items: [],
        createRules: 'req',
        updateRules: [],
        md: 6,
        disabled: true
      },
      withContent: {
        type: 'switch',
        label: this.$t('hosting.servers.domains.domainTransferDialog.withContent'),
        md: 6,
        disabled: true
      }
    }
  },
  methods: {
    currentItemChanges(changes) {
      this.currentItem = Object.assign({}, this.editedItem, changes)
    },
    async save(e) {
      this.saveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          const response = await this.$store.dispatch('hostingServers/domainTransfer', this.currentItem)
          if (response.success === true) {
            // await this.$toast.success('OK')
            await this.$store.dispatch('toggleDialogEventLog')
            this.close()
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.saveLoading = false
      }
    },
    close() {
      this.currentItem = {}
      this.$emit('close', this)
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('hosting.servers.domains.domainTransferDialog.validation.fieldIsRequired')
      }
      return !!value || this.$t('hosting.servers.domains.domainTransferDialog.validation.fieldIsRequired')
    }
  }
}
</script>

<style scoped>

</style>
