<template>
  <div>
    <dialog-form
      :dialog="dialog"
      :form-title="dnsFormTitle"
      :form-scheme="dnsFormScheme"
      :save-loading="dnsSaveLoading"
      :edited-item="dnsEditedItem"
      @changeEditedItem="dnsChangeEditedItem"
      @save="dnsSave"
      @close="closeDnsDialog"
    />
    <dialog-confirm
      :dialog="dnsDialogDelete"
      :title="$t('domains.cloudflare.dns.dialog.removeConfirmTitle')"
      :confirm-loading="dnsRemoveLoading"
      @close="closeDialogDeleteClose"
      @confirm="dnsRemoveConfirm"
    />
  </div>
</template>

<script>
export default {
  name: 'CloudflareDns',
  props: {
    dialog: Boolean,
    action: {
      type: String,
      default: 'create'
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    dnsLoading: false,
    dnsDialog: false,
    dnsFormTitle: '',
    dnsFormScheme: {},
    dnsSaveLoading: false,
    dnsEditedItem: {},
    dnsDialogDelete: false,
    dnsRemoveLoading: false
  }),
  watch: {
    dialog() {
      this.dnsEditedItem = Object.assign({}, {
        id: this.editedItem.id,
        zoneId: this.editedItem.zoneId,
        dnsZoneId: this.editedItem.dnsZoneId || null,
        type: this.editedItem.type,
        name: this.editedItem.name,
        content: this.editedItem.content,
        proxied: Boolean(this.editedItem.proxied)
      })
    }
  },
  mounted () {
    this.dnsFormTitle = this.action === 'update'
      ? this.$t('domains.cloudflare.dns.dialog.titleEdit')
      : this.$t('domains.cloudflare.dns.dialog.titleAdd')
    this.dnsFormScheme = {
      type: {
        type: 'select',
        label: this.$t('domains.cloudflare.dns.dialog.type'),
        items: ['A', 'CNAME', 'TXT'],
        createRules: 'req',
        md: 4
      },
      name: {
        type: 'text',
        label: this.$t('domains.cloudflare.dns.dialog.name'),
        createRules: 'req',
        updateRules: [],
        md: 4
      },
      content: {
        type: 'text',
        label: this.$t('domains.cloudflare.dns.dialog.content'),
        createRules: 'req',
        updateRules: [],
        md: 4
      },
      proxied: {
        type: 'switch',
        label: this.$t('domains.cloudflare.dns.dialog.proxied'),
        createRules: 'req',
        updateRules: [],
        md: 12,
        class: 'mt-3'
      }
    }
  },
  created () {
    this.$parent.$on('zoneDnsRemove', this.dnsRemove)
  },
  methods: {
    dnsChangeEditedItem (event) {
      this.dnsEditedItem = Object.assign({}, this.dnsEditedItem, event)
    },
    async dnsSave(e) {
      this.dnsSaveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          const response = await this.$store.dispatch('cloudflare/dnsSave', this.dnsEditedItem)
          if (response.success === true) {
            await this.$toast.success(this.$t('domains.cloudflare.dns.dialog.updateSuccess'))
            this.closeDnsDialog()
          } else {
            await this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.dnsSaveLoading = false
      }
    },
    dnsRemove (item) {
      this.dnsEditedItem = Object.assign({}, item, this.dnsEditedItem)
      this.dnsDialogDelete = true
    },
    async dnsRemoveConfirm () {
      this.dnsRemoveLoading = true
      try {
        const response = await this.$store.dispatch('cloudflare/dnsRemove', this.dnsEditedItem)
        if (response.success === true) {
          await this.$toast.success(this.$t('domains.cloudflare.dns.dialog.removeSuccess'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.dnsRemoveLoading = false
        this.closeDialogDeleteClose()
      }
    },
    closeDnsDialog() {
      this.$emit('close', this)
    },
    closeDialogDeleteClose() {
      this.dnsDialogDelete = false
      this.dnsEditedItem = Object.assign({}, {
        domain: '',
        subdomain: '',
        type: '',
        content: ''
      })
    }
  }
}
</script>

<style scoped>

</style>
